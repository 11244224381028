import React, { useState } from "react";
import VIDEO from "../assets/IMG_0645.MP4";

import { Link } from "react-router-dom";

const Search = ({ setSearchTerm }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = () => {
    setSearchTerm(searchQuery);
  };
  return (
    <div className="pl-0">
      <video
        className="absolute tablet:top-0 desktop:left-0 w-screen desktop:h-[750px] laptop:h-[550px] tablet:h-[190px]   aspect-video desktop:object-cover laptop:object-cover object-cover left-0  overflow-x-hidden desktop:px-16 laptop:px-14 desktop:mt-32 laptop:mt-24 laptop:pb-20 desktop:-ml-1  laptop:mx-4 mobile:mt-24 mobile:pb-10 mobile:mx-8 mobile:ml-auto "
        src={VIDEO}
        muted
        autoPlay
        loop
        alt="Search_Container"
      />
      <h1 className="absolute font-PTSerif text-yellow-900 font-bold desktop:text-4xl laptop:text-3xl  desktop:mx-[650px] laptop:mx-[310px] mx-24 desktop:my-[380px] laptop:my-72 my-[90px] mobile:my-[160px] mobile:mx-20 whitespace-nowrap  items-center text-center mobile:text-lg laptop:py-10 laptop:px-10">
        Committed To Healthier Tomorrow
      </h1>

      <div className="mobile:py-8 mobile:px-10">
        <input
          className="z-20 relative desktop:w-[800px] laptop:w-[500px] mobile:w-[190px] desktop:mx-[500px] laptop:mx-60 mx-12 desktop:my-[450px] laptop:my-[350px] my-28 rounded-l-lg desktop:py-2 laptop:py-2  outline-none mobile:py-1 mobile:mt-40"
          type="text"
          placeholder={
            searchQuery
              ? ""
              : "Search your Medicine or Healthcare essentials here"
          }
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button className="relative bg-yellow-900 desktop:px-6 px-3 laptop:px-8   desktop:py-[5px] laptop:py-[5px] py-[4.3px] desktop:-ml-[500px] laptop:-ml-60 -ml-12 desktop:mt-0 laptop:mt-0  rounded-r-lg font-OpenSans  desktop:text-xl laptop:text-xl  text-white mobile:py-1 ">
          {" "}
          <Link to={`/products/:search=${searchQuery}`} onClick={handleSearch}>
            Search
          </Link>
        </button>
      </div>
    </div>
  );
};

export default Search;
