import React, { useState } from 'react';

import productData from './productList.json'

const ProductCard = ({ product }) => {

  const [showDescription, setShowDescription] = useState(false);
  const { ProductName, ProductDesc, ProductImage, ProductPrice } = product;

  return (
    <div className="max-w-md mx-auto bg-white rounded-lg overflow-hidden shadow-lg mb-4 mt-40 transition duration-300 transform hover:scale-105">
      {ProductImage && (
        <img className="h-48 w-full object-cover" src={ProductImage} alt={ProductName} />
      )}
      <div className="p-6">
        <h2 className="text-2xl font-bold mb-2 text-gray-800">{ProductName}</h2>
        <div className="mt-3 flex items-center justify-between">
          <p className="text-lg text-green-900 font-bold">Price: {ProductPrice}</p>
        </div>

        {showDescription && <p className="text-gray-600 mt-2">{ProductDesc}</p>}
        <button
          className="px-4 py-2 rounded-full bg-blue-500 text-white  focus:outline-none" onClick={() => setShowDescription(!showDescription)}
        >
          {showDescription ? 'Hide Description' : 'Show Description'}
        </button>
      </div>
    </div>
  );
};

const ProductList = () => {
  // const [productData, setProductData] = useState([]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch("https://sheet.best/api/sheets/21b4b5b7-5844-4bf0-aad2-3a43f2ede0c4");
  //       const result = await response.json();
  //       console.log(result);
  //       setProductData(result);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   fetchData();
  // }, []);

  return (
    <>

      <div className="flex flex-wrap justify-center  ml-20 mr-20 ">
        {productData.map((product, index) => (
          <ProductCard key={index} product={product} />
        ))}
      </div>
    </>
  );
};

export default ProductList;
