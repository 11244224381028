import React from 'react'
import { FaFacebook } from "react-icons/fa";
import { FaInstagram, FaSquareXTwitter, FaYoutube } from "react-icons/fa6";
import { Link } from 'react-router-dom';

const SocialMedia = () => {
    return (
        <div className='desktop:mx-[950px] laptop:mx-[570px] mobile:mx-[115px] flex mx-2 desktop:gap-8 laptop:gap-6 mobile:gap-4 mb-24 o '>
            <Link
                to="#"
                className='mt-2 text-4xl hover:text-white'
                target='_blank'
                rel='noreferrer'
            >
                <FaFacebook />
            </Link>

            <Link
                className='mt-2 text-4xl hover:text-white'
                to="#"
                target='_blank'
                rel='noreferrer'
            >
                <FaInstagram />
            </Link>

            <Link
                className='mt-2 text-4xl hover:text-white'
                to="#"
                target='_blank'
                rel='noreferrer'
            >
                <FaSquareXTwitter />
            </Link>

            <Link
                className='mt-2 text-4xl hover:text-white'
                to="#"
                target='_blank'
                rel='noreferrer'
            >
                <FaYoutube />
            </Link>
        </div>
    )
}

export default SocialMedia