import React, { useState } from 'react';
import emailjs from '@emailjs/browser';


const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    message: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const SERVICE_ID = "";
    const TEMPLATE_ID = "";
    const PUBLIC_KEY = "";
    try {
      await emailjs.send(
        SERVICE_ID,
        TEMPLATE_ID,
        formData,
        PUBLIC_KEY
      );

      console.log('Email sent successfully!🚀');
    } catch (error) {
      console.error('Something went wrong while sending email🌋:', error);
    }
  };


  return (
    <>
    {/* <Header /> */}
    
    <div className="desktop:max-w-lg laptop:max-w-fit  desktop:mx-auto laptop:mx-auto  mobile:ml-0 desktop:mt-0   laptop:mt-14 mobile:mt-14  laptop:p-12  bg-gray-200 rounded shadow-2xl desktop:mb-20 laptop:mb-20 mobile:mb-20 ">

      <form onSubmit={handleSubmit} className='mb-10  '>
        <h2 className="desktop:text-4xl laptop:text-3xl mobile:text-3xl mobile:font-semibold desktop:mx-32 laptop:mx-32 mobile:mx-12 laptop:whitespace-nowrap font-PTSerif desktop:mb-4  laptop:mb-4 mobile:mb-4 desktop:whitespace-nowrap mobile:whitespace-nowrap mobile:pl-24 mobile:py-2  laptop:pl-2 desktop:pl-2 desktop:-mt-5 laptop:-mt-8 rounded-2xl">Contact Us</h2>
        <div className="mb-10">
          <label htmlFor="name" className="block text-gray-700 text-lg font-PTSerif font-semibold mb-2 mobile:ml-4">
            Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder='e.g Harry Brown'
            value={formData.name}
            onChange={handleInputChange}
            className="desktop:w-full laptop:w-full p-2 border rounded bg-gray-300 mobile:ml-2 mobile:w-[407px]"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="phone" className="block text-gray-700 text-lg font-PTSerif font-semibold mb-2   mobile:ml-4">
            Phone Number
          </label>
          <input
            type="tel"
            id="phoneNumber"
            name="phoneNumber"
            placeholder='e.g 999-999-9999'
            value={formData.phoneNumber}
            onChange={handleInputChange}
            className="desktop:w-full laptop:w-full p-2 border rounded bg-gray-300 mobile:ml-2 mobile:w-[407px] "
          />
        </div>

        <div className="mb-4">
          <label htmlFor="query" className="block text-gray-700 text-lg font-PTSerif font-semibold mb-2   mobile:ml-4">
            Query
          </label>
          <textarea
            id="message"
            name="message"
            placeholder='Message'
            value={formData.message}
            onChange={handleInputChange}
            className="desktop:w-full laptop:w-full p-2 border rounded bg-gray-300 mobile:ml-2 mobile:w-[407px]"
          />
        </div>

        <button type="submit" value={handleSubmit} className="bg-yellow-600 text-white p-2 desktop:w-full laptop:w-full hover:bg-yellow-700 rounded  mt-10 mobile:w-[425px]">
          Submit
        </button>
      </form>
    </div>
    {/* <Footer /> */}
    </>
  );
};

export default Contact;
