import React from "react";
// import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import Body from "./components/Body";
import Contact from "./components/Contact";
import About from "./components/About";
import ProductList from "./components/Product";
import Header from './components/Header'
import Footer from './components/Footer'

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const App = () => {
  return (
    <Router>
      <Routes>
        {/* <Route
        path="/search/:query"
        render={({ match }) => <ProductList query={match.params.query} />}
      ></Route> */}
        <Route path="/" element={<Body />} />

        <Route path="/contact" element={<><Header /><Contact /><Footer /></>} />

        <Route path="/about" element={<><Header /><About /><Footer /></>} />
        <Route path="/product" element={<><Header /><ProductList /> <Footer /></>} />

        {/* <Link to={`/products/${searchQuery}`}>Search</Link> */}
        <Route
          path="/products/:search"
          render={({ match }) => <><Header /><ProductList query={match.params.query} /><Footer /></>}
          element={<><Header /><ProductList /> <Footer /></>} 
        />
      </Routes>
    </Router>
  );
};

export default App;
