import React from 'react'
import Header from './Header'
import Footer from './Footer'
import About from './About'
import Contact from './Contact'
import Search from './Search'

const Body = () => {

  return (
    <div>
          
      <Header />
      <main>
        <Search />
        <About />
        <Contact />
      </main>
      <Footer />
      
    </div>
  )
}

export default Body
