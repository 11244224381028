import React from 'react'
const About = () => {


  return (
    <>
    {/* <Header /> */}
      <div className="shadow-lg p-5 desktop:-mt-12 laptop:mt-16  desktop:ml-14 laptop:ml-14 desktop:mr-14 mobile:mt-0 mobile:ml-0 bg-gray-200 rounded-2xl
       desktop:mb-24 mobile:space-y-10">
        <header className="text-center bg-gray-300 shadow-lg mobile:p-5 desktop:p-0 laptop:rounded-full desktop:rounded-full mobile:rounded desktop:mt-64 mobile:px-[85px]">
          <p className="text-yellow-900 font-PTSerif mobile:text-xl desktop:-mt-44">
            <span className="font-semibold font-PTSerif desktop:text-5xl mobile:text-3xl text-yellow-600 desktop:ml-12 desktop:mr-5  ">JBS BIOTECH</span> is a leading pharmaceutical company dedicated to improving global health through innovative research, development, and distribution of life-saving medications and healthcare products. Since our founding in [2016], we have remained committed to our mission of [Committed to healthier tomorrow ].
          </p>
        </header>

        <section className="mb-8 p-5">
          <h2 className="text-2xl font-semibold text-yellow-700 font-PTSerif mb-4">Our History</h2>
          <p className="text-yellow-900 font-PTSerif text-lg">

            JBS BIOTECH was established in 2016 by SIMANCHAL MAHAPATRO with a vision to vision statement. Over the years, we have grown into a trusted leader in the pharmaceutical industry, with a strong reputation for excellence in quality, safety, and efficacy.

          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 font-PTSerif text-yellow-700 ">Our Mission</h2>
          <p className="text-yellow-900 text-lg font-PTSerif">

            At [JBS BIOTECH], our mission is to [mission statement]. We are dedicated to advancing medical science, improving patient outcomes, and enhancing quality of life for people around the world.

          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold font-PTSerif text-yellow-700 mb-4">Our Values</h2>
          <ul className="list-disc pl-6">
            <li className="text-yellow-800 font-PTSerif text-xl"><strong>Integrity:</strong> We uphold the highest standards of integrity, ethics, and transparency in everything we do.</li>
            <li className="text-yellow-800 font-PTSerif text-xl"><strong>Innovation:</strong> We are committed to innovation and continuous improvement in research, development, and manufacturing processes.</li>
            <li className="text-yellow-800 font-PTSerif text-xl"><strong>Quality:</strong> We prioritize quality and safety in all our products and operations, ensuring compliance with regulatory standards and guidelines.</li>
            <li className="text-yellow-800 font-PTSerif text-xl"><strong>Collaboration:</strong> We foster a culture of collaboration, teamwork, and partnership with healthcare professionals, researchers, and stakeholders.</li>
          </ul>
        </section>



        <section>
          <h2 className="text-2xl font-semibold font-PTSerif text-yellow-700 mb-4">Areas of Focus</h2>
          <p className="text-lg font-PTSerif text-yellow-900">
            JBS BIOTECH specializes in specific areas of focus, including list of therapeutic areas or product categories. Our diverse portfolio of products addresses the healthcare needs of patients across geographical locations.

          </p>
        </section>

        <section className="mt-8">
          <h2 className="text-2xl font-semibold font-PTSerif text-yellow-700 mb-4">Our Commitment</h2>
          <p className="text-lg  font-PTSerif text-yellow-900 mb-4">

            At JBS BIOTECH, we are dedicated to making a meaningful impact on global health by delivering innovative healthcare solutions that improve the lives of patients and contribute to the advancement of medical science.
          </p>
        </section>
      </div>
    {/* <Footer /> */}
    </>
  );
};

export default About;
