import React from 'react'
import SocialMedia from '../utils/socialMedia';

const Footer = () => {
  return (
    <div className='bg-black text-white desktop:h-52 laptop:h-52 mobile:h-60 mobile:w-full pt-2 overflow-hidden'>
      <div className='destop:w-5/6  '>
        <SocialMedia />
        <div className='desktop:flex laptop:flex justify-center laptop:justify-between  desktop:justify-between desktop:mx-4 laptop:mx-4 text-center desktop:my-20 '>
          <p className='font-PTSerif text-sm desktop:-mt-0 '>
          201, A Suntek Grandeur, Opposite Andheri Subway, SV Road, Andheri, Mumbai, 400058
          </p>
          <p className='font-PTSerif text-sm'> © 2016-2024 JBS BioTech | All Rights Reserved
          </p>
        </div>
      </div>
    </div>
  )
}


export default Footer
