import React, { useState } from 'react';
import LOGO from '../assets/JBS LOGO.png';
import { Link } from 'react-router-dom';
import { IoMenu } from 'react-icons/io5';

const Header = () => {
  const [isMenu, setIsMenu] = useState(false);
  const [activeLink, setActiveLink] = useState(null);

  const toggleMenu = () => {
    setIsMenu(!isMenu);
  };

  const handleClick = (link) => {
    setActiveLink(link);
    toggleMenu(); 
  };

  return (
    <div className='flex justify-between items-center z-50 w-full shadow-xl fixed -mt-18 bg-orange-400  overflow-x-hidden text-white'>
      <img
        className='desktop:h-20 h-14 mx-6 desktop:mx-16 rounded-full'
        src={LOGO}
        alt='Logo'
      />

      <div className='desktop:hidden laptop:hidden mx-6'>
        <IoMenu
          className='text-2xl'
          onClick={toggleMenu}
        />

        {isMenu && (
          <div className='fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center'>
            <ul className='flex flex-col items-center font-PTSerif text-xl gap-[23px]'>
              <Link to='/' onClick={() => handleClick("home")}>
                <li className={`cursor-pointer ${activeLink === "home" ? "text-yellow-900 underline" : "text-white"}`}>Home</li>
              </Link>
              <Link to='/about' onClick={() => handleClick("about")}>
                <li className={`cursor-pointer ${activeLink === "about" ? "text-yellow-900 underline" : "text-white"}`}>About</li>
              </Link>
              <Link to='/product' onClick={() => handleClick("product")}>
                <li className={`cursor-pointer ${activeLink === "product" ? "text-yellow-900 underline" : "text-white"}`}>Products</li>
              </Link>
              <Link to='/contact' onClick={() => handleClick("contact")}>
                <li className={`cursor-pointer ${activeLink === "contact" ? "text-yellow-900 underline" : "text-white"}`}>Contact</li>
              </Link>
            </ul>
          </div>
        )}
      </div>

      <div className='hidden desktop:flex px-6 desktop:px-10 laptop:px-10 laptop:flex'>
        <ul className='flex gap-6 desktop:gap-12 font-PTSerif text-lg desktop:text-2xl'>
          <Link to='/' onClick={() => handleClick("home")}>
            <li className={`cursor-pointer ${activeLink === "home" ? "text-yellow-900 underline" : "text-white"}`}>Home</li>
          </Link>
          <Link to='/about' onClick={() => handleClick("about")}>
            <li className={`cursor-pointer ${activeLink === "about" ? "text-yellow-900 underline" : "text-white"}`}>About</li>
          </Link>
          <Link to='/product' onClick={() => handleClick("product")}>
            <li className={`cursor-pointer ${activeLink === "product" ? "text-yellow-900 underline" : "text-white"}`}>Products</li>
          </Link>
          <Link to='/contact' onClick={() => handleClick("contact")}>
            <li className={`cursor-pointer ${activeLink === "contact" ? "text-yellow-900" : "text-white"}`}>Contact</li>
          </Link>
        </ul>
      </div>
    </div>
  );
};

export default Header;
